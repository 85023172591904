<template>
  <div class="app-container">
    <div class="search-warpper">
      <el-button
        v-p="['externaladmin:fedex:currencyManage:add']"
        @click="addCurrency"
        type="primary"
        style="width: 100px; height: 30px; padding: 0px"
        >添加货币</el-button
      >
      <div v-p="['externaladmin:fedex:currencyManage:search']">
        货币名称：
        <el-input
          placeholder="输入货币名称进行搜索"
          size="mini"
          clearable
          style="width: 240px"
          v-model="query.currencyName"
          @change="searchChange"
        >
        </el-input>
        <el-button
          style="height: 32px; width: 48px; padding: 0px; margin-left: 17px"
          type="primary"
          @click="searchChange"
          icon="el-icon-search"
        ></el-button>
      </div>
    </div>
    <currencyDialog :form="form" :id="id" :show.sync="show" :type="type" :sup_this="sup_this" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #currencyRateSlot="{ scoped: row }"> 1 ：{{ row.currencyRate }} </template>
      <el-table-column fixed="right" label="操作" width="300px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn v-p="['externaladmin:fedex:currencyManage:edit']" @click="onEdit(row)">编辑</color-text-btn>
          <span class="line"></span>
          <Popover @sureHandler="onDelete(row.id)">
            <template #tip>
              <p>您确定要删除已添加的货币信息么?</p>
            </template>
            <template #reference="{ scope: loading }">
              <color-text-btn
                v-p="['externaladmin:fedex:currencyManage:delete']"
                class="update-btn"
                :loading="loading"
                size="mini"
                type="danger"
                >删除</color-text-btn
              >
            </template>
          </Popover>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import initDataMixin from '@/mixins/initData'
import { currencyListCols as cols } from '@/views/fedex/cols'
import currencyDialog from './module/currencyDialog.vue'
import { del } from '@/api/currency'

export default {
  mixins: [initDataMixin],
  components: { currencyDialog },
  data() {
    return {
      id: null,
      form: {},
      cols,
      type: 'add',
      query: {
        currencyName: ''
      },
      sup_this: this,
      currencyName: '',
      url: '/externaladmin/systemService/currency/list',
      method: 'post',
      show: false,
      form: {
        currencyName: null,
        currencyCode: null,
        currencySymbol: null,
        currencyRate: null
      }
    }
  },
  methods: {
    addCurrency() {
      this.type = 'add'
      this.form = {
        currencyName: null,
        currencyCode: null,
        currencySymbol: null,
        currencyRate: null
      }
      this.show = true
    },
    onEdit(data) {
      this.type = 'edit'
      this.id = data.id
      const { currencyName, currencyCode, currencySymbol, currencyRate } = data
      this.form = {
        currencyName,
        currencyCode,
        currencySymbol,
        currencyRate
      }
      this.show = true
    },
    async onDelete(id) {
      try {
        const data = {
          idList: [id]
        }
        await del(data)
        this.init()
        this.$message.success('货币删除成功')
      } catch (error) {
        this.$message.success('货币删除失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .search-warpper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    background-color: #f0f2f5;
    padding: 20px;
  }
}
</style>
