<template>
  <el-dialog
    :title="type === 'add' ? '添加货币' : '编辑货币'"
    :visible.sync="show"
    width="612px"
    class="dialog-warpper"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" label-width="160px" :rules="rules" :validate-on-rule-change="false">
      <el-form-item label="货币名称：" prop="currencyName">
        <el-input size="mini" v-model="form.currencyName" placeholder="请输入货币名称"></el-input>
      </el-form-item>
      <el-form-item label="货币缩写：" prop="currencyCode">
        <el-input size="mini" v-model="form.currencyCode" placeholder="请输入货币缩写"></el-input>
      </el-form-item>
      <el-form-item label="货币符号：" prop="currencySymbol">
        <el-input size="mini" v-model="form.currencySymbol" placeholder="请输入货币符号"></el-input>
      </el-form-item>
      <el-form-item size="mini" label="汇率(兑美元)：" v-model="form.currencyRate" prop="currencyRate">
        <div class="rate-warpper">
          1:
          <el-input
            style="margin-left: 10px; position: relative"
            size="mini"
            v-model="form.currencyRate"
            placeholder="请输入汇率(最多只截取前五位小数)"
          ></el-input>
          <div class="cny">USD</div>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCanceled">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirmed(type)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { create, edit } from '@/api/currency'
import { isMoreZero } from '@/utils/validate'

export default {
  data() {
    var verifyCode = (rule, value, callback) => {
      this.form.currencyCode = value.toUpperCase()
      if (/^[A-Z]{3}$/.test(this.form.currencyCode) == false) {
        callback(new Error('请输入三位字母'))
      } else {
        callback()
      }
      callback()
    }
    return {
      loading: false,
      rules: {
        currencyName: [{ required: true, message: '货币名称必填', trigger: 'blur' }],
        currencyCode: [
          { required: true, message: '货币缩写必填', trigger: 'blur' },
          { validator: verifyCode, trigger: 'blur' }
        ],
        currencySymbol: [{ required: true, message: '货币符号必填', trigger: 'blur' }],
        currencyRate: [
          { required: true, message: '汇率必填', trigger: 'blur' },
          { validator: isMoreZero, trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    form: {
      type: Object
    },
    id: {
      type: Number
    },
    show: {
      type: Boolean,
      deafult: false
    },
    type: {
      type: String,
      default: 'add'
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('update:show', false)
    },
    handleCanceled() {
      this.$emit('update:show', false)
      this.$refs.form.clearValidate()
    },
    handleConfirmed() {
      // if (!this.form.currencyCode) return;
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.loading = true
        this.form.currencyRate = Number(this.form.currencyRate)
        if (this.type === 'add') {
          create(this.form)
            .then(() => {
              this.loading = false
              this.$emit('update:show', false)
              this.sup_this.init()
              this.$message.success('货币添加成功!')
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.loading = true
          const { currencyName, currencyCode, currencySymbol, currencyRate } = this.form
          const data = {
            id: this.id,
            currencyName,
            currencyCode,
            currencySymbol,
            currencyRate
          }
          edit(data)
            .then(() => {
              this.loading = false
              this.$emit('update:show', false)
              this.query = {}
              this.sup_this.init()
              this.$message.success('货币编辑成功!')
              this.$refs.form.resetFields()
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .rate-warpper {
    display: flex;
    align-items: center;
    .cny {
      position: relative;
      left: -85px;
      border: 1px solid #939393;
      width: 66px;
      height: 34px;
      text-align: center;
      line-height: 34px;
    }
  }
  ::v-deep {
    .el-form-item__label {
      color: #595961;
      font-weight: normal;
    }
    .el-input__suffix {
      right: 180px;
    }
    .el-input__prefix,
    .el-input__suffix {
      top: 3px;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
    .el-input__inner {
      width: 246px;
      height: 36px;
    }
  }
}
</style>
